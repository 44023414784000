import { CHIME_MEETING_RECONNECT_TIMEOUT_MS } from 'frontend/constants/chime';
import { MEETING_EVENTS } from 'frontend/services/chime/event-manager';
import { NETWORK_QUALITY_SCORE } from 'frontend/utils/network-connection';
import { TrackedMap } from 'tracked-built-ins';
import { action } from '@ember/object';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { reads } from 'macro-decorators';
import { task, timeout } from 'ember-concurrency';
import Service, { service } from '@ember/service';

export const VIDEO_METRICS_BUFFER_SIZE = 5;
export const METRICS_THRESHOLDS = {
  bitrate: {
    good: 1000,
    suboptimal: 500,
    poor: 150,
  },
  jitter: {
    good: 5,
    suboptimal: 10,
    poor: 30,
  },
  packetLoss: {
    good: 1,
    suboptimal: 3,
    poor: 8,
  },
  latency: {
    good: 100,
    suboptimal: 250,
    poor: 400,
  },
};
export const AUDIO_SIGNAL_STRENGTH = {
  excellent: 1,
  poor: 0.5,
  noConnection: 0,
};

export default class ChimeConnectionHealthService extends Service {
  @service('chime.meeting-manager') meetingManager;
  @service('chime.roster') rosterService;
  @service('chime.sdk') sdkService;
  @service roomChannel;
  @service errorHandling;

  audioDeviceReconnectTimeoutMs = isEmberTesting() ? 1 : 5_000;

  @reads('sdkService.sdk') chimeSdk;
  @reads('meetingManager.localAttendeeId') localAttendeeId;
  @reads('meetingManager.meetingSession') meetingSession;
  @reads('meetingManager.audioVideo') audioVideo;
  @reads('rosterService.roster') rosterMap;
  @reads('rosterService.localAttendeeData') localAttendeeData;
  @reads('meetingSession.eventController') eventController;

  videoScoreBuffer = new TrackedMap();
  attendeesAudioReconnectTimeouts = {};
  #meetingFailureListeners = [];

  setup() {
    this.rosterService.subscribeToRosterChanges(this._onChimeRosterChange);
    this.subscribeToAttendeeVolumeChange(this.localAttendeeId);
    this.setDefaultVideoScoreBuffer(this.localAttendeeId);
    this.setupObservers();
  }

  waitForLocalAttendeeToReconnectTask = task(async () => {
    await timeout(CHIME_MEETING_RECONNECT_TIMEOUT_MS);
    this.handleMeetingFailed();
  });

  updateAttendeeDataTask = task(async (attendeeId, metric) => {
    await timeout(100);
    if (this.isLocalAttendeeHasBadConnection) this.setGoodScoreForRemoteAttendees();
    if (!this.isLocalAttendeeHasBadConnection || this.rosterService.isLocal(attendeeId)) {
      this.rosterService.setAttendeeData(attendeeId, metric);
    }
  });

  waitForAudioToReconnectTask = task(async attendeeId => {
    await timeout(this.audioDeviceReconnectTimeoutMs);
    this.rosterService.setAttendeeData(attendeeId, { lostConnection: true });
  });

  get isLocalAttendeeHasBadConnection() {
    return this.localAttendeeData?.lostConnection || this.localAttendeeData?.hasPoorConnection;
  }

  @action
  metricsDidReceive(metrics) {
    let videoMetrics = metrics.getObservableVideoMetrics();
    if (!Object.keys(videoMetrics).length) return;
    for (const [attendeeId, stats] of Object.entries(videoMetrics)) {
      let metrics = Object.values(stats).at(-1);
      this.setVideoSignalScoreForAttendee(attendeeId, metrics);
    }
  }

  @action
  _onAudioVideoDidStop(status) {
    let { MeetingSessionStatusCode } = this.chimeSdk;
    if (status.statusCode === MeetingSessionStatusCode.Left || !status.isTerminal()) return;

    this.#publishMeetingFail();
    this.errorHandling.notifyError(
      new Error('Meeting Failure', { cause: { statusCode: status.statusCode } })
    );
  }

  setupObservers() {
    this.eventControllerObservers = {
      eventDidReceive: (name, attributes) => {
        switch (name) {
          case MEETING_EVENTS.receivingAudioDropped:
          case MEETING_EVENTS.signalingDropped:
            this.handleLocalAttendeeDrop();
            break;
          case MEETING_EVENTS.meetingReconnected:
            this.handleLocalAttendeeReconnect();
            break;
          case MEETING_EVENTS.audioInputFailed:
          case MEETING_EVENTS.videoInputFailed:
          case MEETING_EVENTS.meetingStartFailed:
          case MEETING_EVENTS.meetingFailed:
            this.handleMeetingFailed(name, attributes);
        }
      },
    };
    this.audioVideoObservers = {
      metricsDidReceive: this.metricsDidReceive,
      audioVideoDidStop: this._onAudioVideoDidStop,
    };
    this.eventController.addObserver(this.eventControllerObservers);
    this.meetingManager.setupAudioVideoObservers(this.audioVideoObservers);
  }

  setDefaultVideoScoreBuffer(attendeeId) {
    this.videoScoreBuffer.set(
      attendeeId,
      new Array(VIDEO_METRICS_BUFFER_SIZE).fill(NETWORK_QUALITY_SCORE.excellent)
    );
    this.rosterService.setAttendeeData(attendeeId, {
      videoSignalScore: NETWORK_QUALITY_SCORE.excellent,
    });
  }

  setVideoSignalScoreForAttendee(attendeeId, videoMetrics) {
    let videoSignalScore = this.getVideoSignalScoreForAttendee(attendeeId, videoMetrics);
    let attendeeVideoBuffer = this.videoScoreBuffer.get(attendeeId);
    if (!attendeeVideoBuffer) {
      this.setDefaultVideoScoreBuffer(attendeeId);
      this.rosterService.setAttendeeData(attendeeId, {
        videoSignalScore: NETWORK_QUALITY_SCORE.excellent,
      });
      return;
    }
    attendeeVideoBuffer.shift();
    attendeeVideoBuffer.push(videoSignalScore);
    let totalScore =
      attendeeVideoBuffer.reduce((acc, score) => acc + score, 0) / attendeeVideoBuffer.length;
    if (this.isLocalAttendeeHasBadConnection) this.setGoodScoreForRemoteAttendees();
    if (!this.isLocalAttendeeHasBadConnection || this.rosterService.isLocal(attendeeId)) {
      this.rosterService.setAttendeeData(attendeeId, { videoSignalScore: totalScore });
    }
  }

  setGoodScoreForRemoteAttendees() {
    this.rosterMap.forEach((_, attendeeId) => {
      if (this.rosterService.isLocal(attendeeId)) return;
      this.rosterService.setAttendeeData(attendeeId, {
        audioSignalScore: NETWORK_QUALITY_SCORE.excellent,
        videoSignalScore: NETWORK_QUALITY_SCORE.excellent,
      });
    });
  }

  getVideoSignalScoreForAttendee(attendeeId, videoMetrics) {
    let metricsObj = this.rosterService.isLocal(attendeeId)
      ? {
          bitrate: videoMetrics.videoUpstreamBitrate,
          jitterMs: videoMetrics.videoUpstreamJitterMs,
          packetLossPercent: videoMetrics.videoUpstreamPacketLossPercent,
          latency: videoMetrics.videoUpstreamRoundTripTimeMs,
        }
      : {
          bitrate: videoMetrics.videoDownstreamBitrate,
          jitterMs: videoMetrics.videoDownstreamJitterMs,
          packetLossPercent: videoMetrics.videoDownstreamPacketLossPercent,
        };

    return this.getVideoSignalScore(metricsObj);
  }

  getVideoSignalScore({ bitrate, jitterMs, packetLossPercent, latency }) {
    const bitrateScore = this.getMetricScore(
      bitrate,
      METRICS_THRESHOLDS.bitrate.good,
      METRICS_THRESHOLDS.bitrate.suboptimal,
      METRICS_THRESHOLDS.bitrate.poor,
      true
    );
    const jitterScore = this.getMetricScore(
      jitterMs,
      METRICS_THRESHOLDS.jitter.good,
      METRICS_THRESHOLDS.jitter.suboptimal,
      METRICS_THRESHOLDS.jitter.poor
    );
    const packetLossScore = this.getMetricScore(
      packetLossPercent,
      METRICS_THRESHOLDS.packetLoss.good,
      METRICS_THRESHOLDS.packetLoss.suboptimal,
      METRICS_THRESHOLDS.packetLoss.poor
    );
    const latencyScore = this.getMetricScore(
      latency,
      METRICS_THRESHOLDS.latency.good,
      METRICS_THRESHOLDS.latency.suboptimal,
      METRICS_THRESHOLDS.latency.poor
    );

    return Math.round((latencyScore + jitterScore + packetLossScore + bitrateScore) / 4);
  }

  getMetricScore(stat, goodThreshold, suboptimalThreshold, poorThreshold, descending = false) {
    if (typeof stat === 'undefined') {
      return NETWORK_QUALITY_SCORE.noConnection;
    }

    if (descending) {
      if (stat >= goodThreshold) return NETWORK_QUALITY_SCORE.excellent;
      if (stat >= suboptimalThreshold) return NETWORK_QUALITY_SCORE.good;
      if (stat >= poorThreshold) return NETWORK_QUALITY_SCORE.poor;
      return NETWORK_QUALITY_SCORE.noConnection;
    }

    if (stat >= poorThreshold) return NETWORK_QUALITY_SCORE.noConnection;
    if (stat >= suboptimalThreshold) return NETWORK_QUALITY_SCORE.poor;
    if (stat > goodThreshold) return NETWORK_QUALITY_SCORE.good;
    return NETWORK_QUALITY_SCORE.excellent;
  }

  getAttendeeAudioSignalScore(signalStrength) {
    switch (signalStrength) {
      case AUDIO_SIGNAL_STRENGTH.excellent:
        return NETWORK_QUALITY_SCORE.excellent;
      case AUDIO_SIGNAL_STRENGTH.poor:
        return NETWORK_QUALITY_SCORE.poor;
      default:
        return NETWORK_QUALITY_SCORE.noConnection;
    }
  }

  @action
  onVolumeIndicatorChange(attendeeId, __, muted, signalStrength) {
    if (signalStrength !== null) {
      this.handleAttendeeAudioSignalChange(attendeeId, signalStrength);
    }

    if (muted === null) return;

    this.rosterService.setAttendeeData(attendeeId, { muted });
  }

  handleAttendeeAudioSignalChange(attendeeId, signalStrength) {
    this.updateAttendeeDataTask.perform(attendeeId, {
      audioSignalScore: this.getAttendeeAudioSignalScore(signalStrength),
    });
    if (signalStrength === 0) {
      this.attendeesAudioReconnectTimeouts[attendeeId] ??=
        this.waitForAudioToReconnectTask.perform(attendeeId);
      return;
    }

    this.rosterService.setAttendeeData(attendeeId, { lostConnection: false });
    this.attendeesAudioReconnectTimeouts[attendeeId]?.cancel();
    delete this.attendeesAudioReconnectTimeouts[attendeeId];
  }

  subscribeToAttendeeVolumeChange(attendeeId) {
    this.audioVideo?.realtimeSubscribeToVolumeIndicator(attendeeId, this.onVolumeIndicatorChange);
  }

  unsubscribeFromAttendeeVolumeChange(attendeeId) {
    this.audioVideo?.realtimeUnsubscribeFromVolumeIndicator(
      attendeeId,
      this.onVolumeIndicatorChange
    );
  }

  handleLocalAttendeeDrop() {
    this.rosterService.setAttendeeData(this.localAttendeeId, { lostConnection: true });
    this.setGoodScoreForRemoteAttendees();
    this.waitForLocalAttendeeToReconnectTask.perform();
  }

  handleLocalAttendeeReconnect() {
    this.waitForLocalAttendeeToReconnectTask.cancelAll();
    this.rosterService.setAttendeeData(this.localAttendeeId, { lostConnection: false });
  }

  handleMeetingFailed(errorName, attributes = {}) {
    if (errorName) {
      const { meetingHistory, ...otherAttributes } = attributes;

      this.errorHandling.notifyError(
        new Error(errorName, {
          cause: {
            ...otherAttributes,
            meetingHistory: meetingHistory?.filter(({ timestampMs }) => {
              return Date.now() - timestampMs < 5 * 60 * 1000;
            }),
          },
        })
      );
    }

    this.#publishMeetingFail();
  }

  @action
  _onChimeRosterChange({ attendee, isPresent }) {
    if (!isPresent && !attendee.lostConnection) {
      this.unsubscribeFromAttendeeVolumeChange(attendee.chimeAttendeeId);
    } else {
      this.subscribeToAttendeeVolumeChange(attendee.chimeAttendeeId);
    }
  }

  reset() {
    this.updateAttendeeDataTask.cancelAll();
    this.waitForAudioToReconnectTask.cancelAll();
    this.waitForLocalAttendeeToReconnectTask.cancelAll();
    this.meetingManager.removeAudioVideoObservers(this.audioVideoObservers);
    this.eventController?.removeObserver(this.eventControllerObservers);
    this.unsubscribeFromAttendeeVolumeChange(this.localAttendeeId);
    this.eventControllerObservers = null;
    this.audioVideoObservers = null;
    this.#meetingFailureListeners = [];
  }

  subscribeToMeetingFail(callback) {
    this.#meetingFailureListeners.push(callback);
  }

  #publishMeetingFail() {
    this.#meetingFailureListeners.forEach(callback => callback());
  }
}
