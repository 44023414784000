import { createElement } from 'frontend/utils/banners';
import { information } from 'frontend/utils/modals';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { routeFor } from 'frontend/helpers/route-for';
import Service, { service } from '@ember/service';

const NOT_ALLOWED_ERROR = 'NotAllowedError';
const NOT_FOUND_ERROR = 'NotFoundError';
const NOT_READABLE_ERROR = 'NotReadableError';
const DEVICE_ERROR_MESSAGE =
  "The browser couldn't capture audio and video with your microphone and camera, probably because another app or tab has reserved them.<pre></pre>Try closing any apps or tabs that may be reserving the microphone and camera, then refresh this page. If that doesn't work, restart your browser";

export default class ErrorHandlingService extends Service {
  @service mixpanel;

  notifyError(err) {
    if (err) {
      window._bugsnagClient?.notify(err);
    }
  }

  systemPermissionsError() {
    information({
      title: 'Update your settings to share your screen',
      html: createElement('p', {
        innerHTML:
          'To start sharing your entire screen or application window, ' +
          'first update the Screen Recording settings for this browser in your ' +
          '<b>System Preferences</b>.',
      }),
      confirmButtonText: 'Dismiss',
    });
  }

  async roomDisconnectedError() {
    await information({
      title: 'Refresh your session',
      text: 'Your Telehealth session did not load properly. Refreshing should resolve the issue.',
      customClass: {
        confirmButton: 'button primary',
      },
      confirmButtonText: 'Refresh',
      allowOutsideClick: false,
      showCloseButton: false,
    });

    if (isEmberTesting()) {
      return;
    }

    location.reload();
  }

  async getDevicesError(error, hasVideo = false, hasAudio = false) {
    let headline = '';
    let message = '';
    let url = '';
    let title = "We can't get access to your media";
    let confirmButtonText = 'Try again';

    switch (true) {
      // This error is emitted when the user or the user's system has denied permission to use the media devices
      case error?.name === NOT_ALLOWED_ERROR:
        headline = "Can't access your media:";
        url = routeFor(['support_article_url', '360041146032']);
        if (error?.message === 'Permission denied by system') {
          // Chrome only
          message =
            'The operating system has blocked the browser from accessing the microphone or camera. Please check your operating system settings.';
        } else {
          message =
            'Telehealth requires access to your microphone and camera before you can join a video appointment. To allow access, open <b>System Preferences</b>.';
        }
        message += ` <a href="${url}">Learn more</a>`;
        break;

      // This error is emitted when input devices are not connected or disabled in the OS settings
      case error?.name === NOT_FOUND_ERROR:
        title = "Can't access your microphone or camera";
        headline = '';
        message = DEVICE_ERROR_MESSAGE;
        confirmButtonText = 'Refresh';
        break;

      // This error is emitted when devices are reserved by other app like Zoom, Skype etc. (Windows only)
      case error?.name === NOT_READABLE_ERROR:
        title = "Can't access your microphone or camera";
        headline = '';
        message = `${DEVICE_ERROR_MESSAGE} or computer.`;
        confirmButtonText = 'Refresh';
        break;

      // Other getUserMedia errors are less likely to happen in this app. Here we will display
      // the system's error message directly to the user.
      case Boolean(error):
        headline = 'Error Acquiring Media:';
        message = `${error?.name} ${error?.message}`;
        break;

      case !hasAudio && !hasVideo:
        headline = "Can't detect camera or microphone:";
        message = 'Other participants in the room will be unable to see and hear you.';
        break;

      case !hasVideo:
        headline = "Can't detect camera:";
        message = 'Other participants in the room will be unable to see you.';
        break;

      case !hasAudio:
        headline = "Can't detect microphone:";
        message = 'Other participants in the room will be unable to hear you.';
    }

    this.notifyError(error);

    return await information({
      title,
      html: createElement('p', {
        innerHTML: `<strong>${headline}</strong>` + `<p>${message}</p>`,
      }),
      confirmButtonText,
      allowOutsideClick: false,
    }).then(result => {
      if (
        [NOT_ALLOWED_ERROR, NOT_FOUND_ERROR, NOT_READABLE_ERROR].includes(error?.name) &&
        result.isConfirmed
      )
        location.reload();
    });
  }

  isGetDevicesError(error) {
    return [NOT_ALLOWED_ERROR, NOT_FOUND_ERROR, NOT_READABLE_ERROR].includes(error?.name);
  }

  async connectRoomError(error, { token, sessionId } = {}) {
    this.mixpanel.track('error: Telehealth welcome screen', {
      token,
      sessionId,
      error,
      skipPrefix: true,
    });
    this.notifyError(error);

    return information({
      title: "We can't connect to the room",
      text: 'Please, check your internet connection and try again.',
      confirmButtonText: 'Try again',
      allowOutsideClick: false,
    });
  }
}
