import { MEETING_EVENTS } from 'frontend/services/chime/event-manager';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { task, timeout } from 'ember-concurrency';

const EVENT_LOGGER_URL = '/frontend/events';
const FLUSH_BUFFER_INTERVAL = 30_000;
const BUFFERS = {
  buffer1: 'buffer1',
  buffer2: 'buffer2',
};

const NUMERIC_METRICS = [
  'audioPacketLossPercent',
  'audioUpstreamJitterMs',
  'audioUpstreamRoundTripTimeMs',
  'videoUpstreamBitrate',
  'videoUpstreamJitterMs',
  'videoUpstreamPacketLossPercent',
  'videoUpstreamRoundTripTimeMs',
];

export default class EventLogger {
  buffer1 = [];
  buffer2 = [];
  activeBufferName;

  constructor(chimeSdk) {
    let { POSTLogger, LogLevel } = chimeSdk;

    let loggerOptions = {
      url: EVENT_LOGGER_URL,
      logLevel: LogLevel.INFO,
    };

    this.postLogger = new POSTLogger(loggerOptions);
    this.activeBufferName = 'buffer1';
    this.switchBufferTask.perform();
  }

  log(event, attributes) {
    this.postLogger.info(JSON.stringify({ event, attributes }));
  }

  async destroy() {
    this.postLogger.destroy();
    this.switchBufferTask.cancelAll();
    await this.flushBufferTask.perform();
  }

  bufferLog(event, attributes) {
    if (MEETING_EVENTS.metricsDidReceive !== event) return;
    this[this.activeBufferName].push({ event, attributes });
  }

  switchBufferTask = task(async ({ force } = {}) => {
    if (isEmberTesting()) return;
    if (!force) await timeout(FLUSH_BUFFER_INTERVAL);
    this.activeBufferName === BUFFERS.buffer1
      ? (this.activeBufferName = BUFFERS.buffer2)
      : (this.activeBufferName = BUFFERS.buffer1);
    this.flushBufferTask.perform();
    this.switchBufferTask.perform();
  });

  flushBufferTask = task(async () => {
    let buffer = this[this.activeBufferName];
    if (buffer.length === 0) return;
    this[this.activeBufferName] = [];
    let sumAttributes = {};
    NUMERIC_METRICS.forEach(metric => (sumAttributes[metric] = 0));

    let eventsCount = 0;
    buffer.forEach(({ attributes }) => {
      NUMERIC_METRICS.forEach(
        metric => (sumAttributes[metric] = sumAttributes[metric] + attributes[metric] || 0)
      );
      eventsCount++;
    });

    let averageAttributes = {};

    NUMERIC_METRICS.forEach(metric => {
      averageAttributes[metric] = sumAttributes[metric] / eventsCount;
    });

    let firstEvent = buffer[0];

    this.log(firstEvent.event, {
      ...averageAttributes,
      externalMeetingId: firstEvent.attributes.externalMeetingId,
      attendeeId: firstEvent.attributes.attendeeId,
    });
  });
}
