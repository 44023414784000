import { DEFAULT_ZOOM_COEFFICIENT, VIDEO_QUALITY_TYPES } from 'frontend/constants';
import { PROCESSOR_TYPES, VIRTUAL_BACKGROUNDS_MAP } from 'frontend/constants/backgrounds';
import { QUALITY_OPTIONS } from 'frontend/constants/chime';
import { action } from '@ember/object';
import { findDeviceById } from 'frontend/utils/devices';
import { importAsset } from 'frontend/helpers/import-asset';
import { or, reads } from 'macro-decorators';
import { tracked } from 'tracked-built-ins';
import CropVideoChimeProcessor from 'frontend/utils/processors/crop-video-chime';
import Service, { service } from '@ember/service';

export default class ChimeLocalAudioVideoService extends Service {
  @service('chime.meeting-manager') meetingManager;
  @service('chime.sdk') sdkService;
  @service persistentProperties;

  @tracked tileId = null;
  @tracked audioInputDevices;
  @tracked audioOutputDevices;
  @tracked videoInputDevices;
  @tracked selectedAudioInputDevice;
  @tracked selectedAudioOutputDevice;
  @tracked selectedVideoInputDevice;
  @tracked videoInput;
  @tracked isVoiceFocusSupported;
  @tracked isVideoProcessingSupported;

  voiceFocusDevice = null;
  audioVideoObserver;
  deviceChangeObserver;

  @reads('persistentProperties.selectedAudioInputDeviceId') preferredAudioInputDevice;
  @reads('persistentProperties.selectedAudioOutputDeviceId') preferredAudioOutputDevice;
  @reads('persistentProperties.selectedVideoDeviceId') preferredVideoInputDevice;
  @reads('persistentProperties.selectedVideoQuality') selectedVideoQuality;
  @reads('persistentProperties.selectedVideoProcessorType') selectedVideoProcessorType;
  @reads('persistentProperties.noiseCancellationEnabled') noiseCancellationEnabled;
  @reads('persistentProperties.isAudioPublished') isAudioPublished;
  @reads('persistentProperties.isVideoPublished') isVideoPublished;
  @reads('meetingManager.audioVideo') audioVideo;
  @reads('meetingManager.isWebAudioEnabled') isWebAudioEnabled;
  @reads('sdkService.sdk') chimeSdk;
  @or('videoTransformDevice', 'selectedVideoInputDevice') activeVideoDevice;

  setup() {
    this.audioVideoObserver = {
      audioVideoDidStart: this.audioVideoDidStart,
      audioVideoDidStop: this.audioVideoDidStop,
      videoTileDidUpdate: this.videoTileDidUpdate,
      videoTileWasRemoved: this.videoTileWasRemoved,
    };
    this.audioVideo.addObserver(this.audioVideoObserver);
  }

  async setupDevices() {
    this.deviceChangeObserver = {
      audioInputsChanged: this.audioInputsChanged,
      audioOutputsChanged: this.audioOutputsChanged,
      videoInputsChanged: this.videoInputsChanged,
    };
    this.isVoiceFocusSupported ??= this.isWebAudioEnabled;

    this.audioVideo.addDeviceChangeObserver(this.deviceChangeObserver);
    this.audioVideo.realtimeSubscribeToMuteAndUnmuteLocalAudio(this.onMutedStateChange);
    this.#checkVideoProcessingSupport();
    await this.startDevices();

    if (this.isAudioPublished) return;
    this.audioVideo.realtimeMuteLocalAudio();
  }

  reset() {
    this.tileId = null;
    this.audioVideo?.removeObserver(this.audioVideoObserver);
    this.audioVideo?.removeDeviceChangeObserver(this.deviceChangeObserver);
  }

  initialConstraint() {
    switch (this.selectedVideoQuality) {
      case VIDEO_QUALITY_TYPES.ld:
        return QUALITY_OPTIONS[VIDEO_QUALITY_TYPES.ld];
      case VIDEO_QUALITY_TYPES.sd:
        return QUALITY_OPTIONS[VIDEO_QUALITY_TYPES.sd];
      default:
        return QUALITY_OPTIONS[VIDEO_QUALITY_TYPES.hd];
    }
  }

  async startDevices() {
    let promises = [];

    if (!this.isStartedDevices) {
      let videoConstraint = this.initialConstraint();
      await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          width: videoConstraint.width,
          height: videoConstraint.height,
          frameRate: videoConstraint.frameRate,
        },
      });

      await this.updateDeviceLists();
      this.updateSelectedDevices();
      promises.push(this.handleVideoInputChange());
    }

    promises.push(this.handleAudioInputChange(), this.handleAudioOutputChange());

    this.isStartedDevices = true;
    return Promise.all(promises);
  }

  async updateDeviceLists() {
    let [audioInputDevices = [], audioOutputDevices = [], videoInputDevices = []] =
      await Promise.all([
        this.audioVideo?.listAudioInputDevices(),
        this.audioVideo?.listAudioOutputDevices(),
        this.audioVideo?.listVideoInputDevices(),
      ]);
    this.audioInputDevices = audioInputDevices;
    this.audioOutputDevices = audioOutputDevices;
    this.videoInputDevices = videoInputDevices;
  }

  updateSelectedDevices() {
    this.selectedAudioInputDevice ||= findDeviceById(
      this.audioInputDevices,
      this.preferredAudioInputDevice
    );
    this.selectedAudioOutputDevice ||= findDeviceById(
      this.audioOutputDevices,
      this.preferredAudioOutputDevice
    );
    this.selectedVideoInputDevice ||= findDeviceById(
      this.videoInputDevices,
      this.preferredVideoInputDevice
    );
  }

  updatePreferredAudioInputDevice(deviceId) {
    this.persistentProperties.setProp('selectedAudioInputDeviceId', deviceId);
    this.selectedAudioInputDevice = deviceId;
    this.handleAudioInputChange();
  }

  updatePreferredVideoInputDevice(deviceId) {
    this.persistentProperties.setProp('selectedVideoDeviceId', deviceId);
    this.selectedVideoInputDevice = deviceId;
    this.handleVideoInputChange();
  }

  updatePreferredAudioOutputDevice(deviceId) {
    this.persistentProperties.setProp('selectedAudioOutputDeviceId', deviceId);
    this.selectedAudioOutputDevice = deviceId;
    this.handleAudioOutputChange();
  }

  async handleAudioInputChange() {
    if (!this.selectedAudioInputDevice) return;
    await this.startAudioInput();
  }

  async startAudioInput() {
    let device = this.useNoiseCancellation
      ? await this.createOrUpdateVoiceFocusDevice()
      : this.#getSelectedAudioInputDevice();

    await this.audioVideo.startAudioInput(device);
  }

  async createOrUpdateVoiceFocusDevice() {
    if (this.voiceFocusDevice) {
      if (this.selectedAudioInputDevice !== this.voiceFocusDevice.device) {
        await this.updateVoiceFocusDevice();
      }
      return this.voiceFocusDevice;
    } else {
      return (await this.createVoiceFocusDevice()) || this.selectedAudioInputDevice;
    }
  }

  async createVoiceFocusDevice() {
    let { VoiceFocusDeviceTransformer } = this.chimeSdk;

    try {
      let transformer = await VoiceFocusDeviceTransformer.create();

      this.isVoiceFocusSupported = transformer.isSupported();
      if (!this.isVoiceFocusSupported) {
        return;
      }

      this.voiceFocusDevice = await transformer.createTransformDevice(
        this.selectedAudioInputDevice
      );
      return this.voiceFocusDevice;
    } catch (e) {
      this.isVoiceFocusSupported = false;
    }
  }

  async updateVoiceFocusDevice() {
    this.voiceFocusDevice = await this.voiceFocusDevice.chooseNewInnerDevice(
      this.selectedAudioInputDevice
    );
  }

  get useNoiseCancellation() {
    return this.noiseCancellationEnabled && this.isVoiceFocusSupported;
  }

  async handleAudioOutputChange() {
    let { DefaultBrowserBehavior } = this.chimeSdk;

    if (!new DefaultBrowserBehavior().supportsSetSinkId()) return;

    await this.audioVideo.chooseAudioOutput(this.selectedAudioOutputDevice);
  }

  async handleVideoInputChange() {
    if (this.videoInput) {
      await this.videoTransformDevice?.stop();
      await this.stopVideoInput();
      this.videoFxProcessor = null;
    }

    this.selectVideoQuality(this.selectedVideoQuality);
    if (!this.isVideoPublished || !this.selectedVideoInputDevice) return;
    await this.setVideoProcessor(this.selectedVideoProcessorType);
    return this.startVideoInputDevice();
  }

  async toggleVideo() {
    if (this.isVideoPublished) {
      await this.stopVideoInput();
    } else {
      await this.startVideoInputDevice();
    }
    this.persistentProperties.setProp('isVideoPublished', !this.isVideoPublished);
  }

  async startVideoInputDevice() {
    if (!this.audioVideo) {
      window._bugsnagClient.leaveBreadcrumb('AudioVideo is undefined');
      return;
    }

    this.videoInput = await this.startLocalVideo();
    if (this.meetingManager.isConnected) this.audioVideo.startLocalVideoTile();
  }

  async startLocalVideo() {
    return this.audioVideo?.startVideoInput(this.activeVideoDevice);
  }

  async stopVideoInput() {
    if (this.meetingManager.isConnected) this.audioVideo?.stopLocalVideoTile();

    await this.audioVideo?.stopVideoInput();
    this.audioVideo?.removeLocalVideoTile();
    this.videoInput = null;
  }

  stopDevices() {
    this.isStartedDevices = false;
    return Promise.all([this.stopVideoInput(), this.audioVideo?.stopAudioInput()]);
  }

  selectVideoQuality(quality = VIDEO_QUALITY_TYPES.hd) {
    if (quality === VIDEO_QUALITY_TYPES.auto || !QUALITY_OPTIONS[quality]) {
      quality = VIDEO_QUALITY_TYPES.hd; // todo: process auto quality
    }
    let { width, height, frameRate, maxBandwidth } = QUALITY_OPTIONS[quality];

    this.audioVideo.chooseVideoInputQuality(width, height, frameRate);
    this.audioVideo.setVideoMaxBandwidthKbps(maxBandwidth);
  }

  @action
  videoTileDidUpdate(tileState) {
    if (
      !this.audioVideo ||
      !tileState.localTile ||
      !tileState.tileId ||
      this.tileId === tileState.tileId
    )
      return;

    this.tileId = tileState.tileId;
  }

  @action
  videoTileWasRemoved(tileId) {
    if (tileId !== this.tileId) return;

    this.tileId = null;
  }

  @action
  bindLocalVideo(videoElement) {
    this.audioVideo.bindVideoElement(this.tileId, videoElement);
  }

  @action
  onMutedStateChange(localMuted) {
    this.persistentProperties.setProp('isAudioPublished', !localMuted);
  }

  @action
  audioVideoDidStart() {
    if (!this.videoInput) return;

    this.audioVideo?.startLocalVideoTile();
  }

  @action
  audioVideoDidStop() {
    this.tileId = null;
  }

  @action
  audioInputsChanged(freshAudioInputDeviceList) {
    this.audioInputDevices = freshAudioInputDeviceList;
    let shouldUpdateDevice = this.shouldUpdateSelectedDevice(
      this.audioInputDevices,
      this.selectedAudioInputDevice
    );
    if (!shouldUpdateDevice) return;

    this.selectedAudioInputDevice = freshAudioInputDeviceList[0]?.deviceId;
    this.handleAudioInputChange();
  }

  @action
  audioOutputsChanged(freshAudioOutputDeviceList) {
    this.audioOutputDevices = freshAudioOutputDeviceList;
    let shouldUpdateDevice = this.shouldUpdateSelectedDevice(
      this.audioOutputDevices,
      this.selectedAudioOutputDevice
    );

    if (!shouldUpdateDevice) return;

    this.selectedAudioOutputDevice = freshAudioOutputDeviceList[0]?.deviceId;
    this.handleAudioOutputChange();
  }

  @action
  videoInputsChanged(freshVideoInputDeviceList) {
    this.videoInputDevices = freshVideoInputDeviceList;
    let shouldUpdateDevice = this.shouldUpdateSelectedDevice(
      this.videoInputDevices,
      this.selectedVideoInputDevice
    );

    if (!shouldUpdateDevice) return;

    this.selectedVideoInputDevice = freshVideoInputDeviceList[0]?.deviceId;
    this.handleVideoInputChange();
  }

  shouldUpdateSelectedDevice(devices, selectedDeviceId) {
    if (selectedDeviceId === 'default') return true;

    let isDeviceSelected = !!selectedDeviceId;
    let isDeviceAvailable = devices.length > 0;
    let isSelectedDeviceAvailable = devices.some(device => device.deviceId === selectedDeviceId);

    return (!isDeviceSelected && isDeviceAvailable) || !isSelectedDeviceAvailable;
  }

  async setVideoProcessor(processorType) {
    let { DefaultVideoTransformDevice, CanvasVideoFrameBuffer } = this.chimeSdk;

    if (!processorType) return;

    switch (processorType) {
      case PROCESSOR_TYPES.none:
        this.videoFxProcessor = null;
        this.voiceFocusDevice?.stop();
        this.videoTransformDevice = null;
        break;
      case PROCESSOR_TYPES.crop:
        this.videoFxProcessor = null;
        this.videoTransformDevice = new DefaultVideoTransformDevice(
          this.meetingManager.logger,
          this.selectedVideoInputDevice,
          [new CropVideoChimeProcessor(DEFAULT_ZOOM_COEFFICIENT, CanvasVideoFrameBuffer)]
        );
        break;
      default:
        await this.#processVideoFxProcessor(processorType);
    }
  }

  async updateVideoProcessor(newProcessorType) {
    let processorType = newProcessorType || PROCESSOR_TYPES.none;

    await this.setVideoProcessor(processorType);
    this.persistentProperties.setProp('selectedVideoProcessorType', processorType);

    if (!this.videoInput) return;
    await this.startVideoInputDevice();
  }

  getVideoFxConfig(processorType) {
    let videoFxConfig = {
      backgroundBlur: { isEnabled: false, strength: 'medium' },
      backgroundReplacement: { isEnabled: false, backgroundImageURL: null, defaultColor: null },
    };

    switch (processorType) {
      case PROCESSOR_TYPES.blur:
        videoFxConfig.backgroundBlur.isEnabled = true;
        break;
      default: {
        if (!VIRTUAL_BACKGROUNDS_MAP[processorType]) break;

        let srcKey =
          this.selectedVideoQuality === VIDEO_QUALITY_TYPES.hd
            ? VIDEO_QUALITY_TYPES.hd
            : VIDEO_QUALITY_TYPES.sd;
        let importedImage = importAsset([VIRTUAL_BACKGROUNDS_MAP[processorType][srcKey]]);

        videoFxConfig.backgroundReplacement.isEnabled = true;
        videoFxConfig.backgroundReplacement.backgroundImageURL = importedImage;
      }
    }

    return videoFxConfig;
  }

  async #checkVideoProcessingSupport() {
    let { VideoFxProcessor } = this.chimeSdk;

    this.isVideoProcessingSupported ??= await VideoFxProcessor.isSupported();
  }

  async #processVideoFxProcessor(processorType) {
    let { VideoFxProcessor, DefaultVideoTransformDevice } = this.chimeSdk;
    let videoFxConfig = this.getVideoFxConfig(processorType);

    if (this.videoFxProcessor) return this.videoFxProcessor.setEffectConfig(videoFxConfig);

    this.videoFxProcessor = await VideoFxProcessor.create(
      this.meetingManager.logger,
      videoFxConfig
    );
    this.videoTransformDevice = new DefaultVideoTransformDevice(
      this.meetingManager.logger,
      this.selectedVideoInputDevice,
      [this.videoFxProcessor]
    );
  }

  #getSelectedAudioInputDevice() {
    if (!this.selectedAudioInputDevice) return null;
    if (!this.isWebAudioEnabled) return this.selectedAudioInputDevice;

    let { SingleNodeAudioTransformDevice } = this.chimeSdk;
    let DummyTransformer = class VolumeTransformDevice extends SingleNodeAudioTransformDevice {
      async createSingleAudioNode(context) {
        return new GainNode(context, { gain: 1 });
      }
    };

    return new DummyTransformer(this.selectedAudioInputDevice);
  }
}
