import * as dialogs from 'frontend/utils/dialogs';
import {
  ERRORS,
  MAX_TRACK_CONFIRMATION_RETRIES,
  PARTICIPANT_EVENTS,
  PUBLICATION_EVENTS,
  ROOM_EVENTS,
  ROOM_STATES,
  TRACK_CONFIRMATION_TIMEOUT_MS,
  TRACK_KINDS,
} from 'frontend/constants/twilio';
import {
  GLOBAL_EVENT,
  GLOBAL_EVENT_TYPES,
  LOGS_PATH,
  ROOM_CHANNEL_MESSAGE_TYPES,
  SESSION_EVENTS_TYPES,
  SHAREABLE_ENTITIES,
  TRACK_TYPE,
  TWILIO_CONNECTION_OPTIONS,
} from 'frontend/constants';
import { HOST_CONTROLS } from 'frontend/constants/settings';
import { Logger, connect } from 'twilio-video';
import { NETWORK_QUALITY_SCORE } from 'frontend/utils/network-connection';
import { Randomizer } from 'frontend/utils/number-array';
import { action, computed, notifyPropertyChange, set, setProperties } from '@ember/object';
import { alias, and, equal, or, reads } from '@ember/object/computed';
import { didCancel, task, timeout, waitForProperty } from 'ember-concurrency';
import { formatNetworkQualityLog } from 'frontend/utils/format-logs';
import { information } from 'frontend/utils/modals';
import { isAudioTrack, isDataTrack, isVideoTrack } from 'frontend/utils/twilio';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { isSafari } from 'frontend/utils/detect-browser';
import { parseTrackInfo } from 'frontend/utils/name-utils';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';
import remote from 'loglevel-plugin-remote';

@classic
export default class TwilioRoomService extends Service {
  @service('twilio/local-tracks') localTracks;
  @service('twilio/data-track') dataTrackService;
  @service('twilio/recording') recordingService;
  @service('whiteboard') whiteboardService;
  @service errorHandling;
  @service chat;
  @service autoQuality;
  @service mixpanel;
  @service router;
  @service roomChannel;
  @service session;
  @service floatingUiElements;
  @service persistentProperties;
  @service appointmentSettings;
  @service soundNotification;
  @service call;
  @service remoteLogger;

  uniqRandom = new Randomizer(10);
  localNetworkQualityLevel;
  room;
  dominantSpeaker = null;
  connectionError = null;
  startSharingTime;
  whiteboardTrack;
  selectedParticipant;
  gridViewActive;
  hideSelfView = false;
  videoTrackPublishing = false;
  audioTrackPublishing = false;
  ensureTrackConfirmationTimeout = TRACK_CONFIRMATION_TIMEOUT_MS;

  @tracked sharedEntity;
  @tracked shareParticipant;

  @alias('persistentProperties.selectedAudioOutputDeviceId') selectedAudioOutputDeviceId;
  @alias('persistentProperties.isAudioPublished') isAudioPublished;
  @alias('persistentProperties.isVideoPublished') isVideoPublished;
  @reads('persistentProperties.uuid') uuid;
  @alias('persistentProperties.noiseCancellationEnabled') noiseCancellationEnabled;
  @alias('localTracks.selectedAudioInputDeviceId') selectedAudioInputDeviceId;
  @alias('localTracks.selectedVideoDeviceId') selectedVideoDeviceId;
  @reads('persistentProperties.userName') userName;
  @reads('dataTrackService.trackPublished') dataTrackPublished;
  @reads('localTracks.selectedVideoQuality') selectedVideoQuality;
  @reads('localTracks.isAutoQuality') isAutoQuality;
  @reads('localTracks.hdNotSupported') hdNotSupported;
  @reads('localTracks.videoTrack') videoTrack;
  @reads('localTracks.audioTrack') audioTrack;
  @reads('localTracks.screenTrack') screenTrack;
  @reads('localTracks.screenAudioTrack') screenAudioTrack;
  @reads('localTracks.selectedVideoProcessorType') selectedVideoProcessorType;
  @reads('localTracks.processorsSupported') processorsSupported;
  @reads('localTracks.hasConfirmedTrack') hasConfirmedTrack;
  @reads('room.localParticipant') localParticipant;
  @reads('session.roomModel') roomModel;
  @reads('session.participants') participants;
  @reads('session.inWaitingRoom') inWaitingRoom;
  @reads('session.isHost') isHost;
  @reads('roomModel.featureThAdaptiveSimulcast') featureThAdaptiveSimulcast;
  @reads('roomModel.featureThEnsureTracks') featureThEnsureTracks;
  @reads('roomModel.featureThClinicianAuth') featureThClinicianAuth;
  @reads('whiteboardService.isLocked') isWhiteboardLocked;
  @reads('call.isConnected') isConnected;
  @and('isAutoQuality', 'autoQuality.notQcif') disableNetworkQualityIndicator;
  @or('selectedParticipant', 'shareParticipant', 'dominantSpeaker', 'participants.firstObject')
  mainSpeaker;
  @equal('sharedEntity', SHAREABLE_ENTITIES.whiteboard) isWhiteboarding;
  @equal('sharedEntity', SHAREABLE_ENTITIES.screen) isScreenShared;

  @(task(function* (token, options) {
    return yield connect(token, options);
  }).restartable())
  _connectTwilioRoomTask;

  @task(function* () {
    yield waitForProperty(this, 'audioTrack');
    let { noiseCancellation } = this.audioTrack;
    if (noiseCancellation) {
      this.noiseCancellationEnabled ? noiseCancellation.enable() : noiseCancellation.disable();
    } else {
      yield this.handleAudioInputChange();
    }
    this.mixpanel.track('noise cancellation', {
      status: this.noiseCancellationEnabled ? 'enabled' : 'disabled',
    });
  })
  toggleNoiseCancellationTask;

  @computed('shareParticipant', 'selectedParticipant')
  get screenInMainView() {
    return this.shareParticipant && !this.selectedParticipant;
  }

  @computed('sharedEntity', 'shareParticipant', 'localParticipant')
  get isSharing() {
    return !!this.sharedEntity && this.shareParticipant === this.localParticipant;
  }

  @computed('isScreenShared', 'isSharing')
  get isSharingScreen() {
    return !!this.isSharing && this.isScreenShared;
  }

  @computed('isWhiteboarding', 'isSharing')
  get isSharingWhiteboard() {
    return !!this.isSharing && this.isWhiteboarding;
  }

  @computed('gridViewActive', 'participants.[]')
  get gridView() {
    if (this.participants.length === 0 && this.gridViewActive) {
      this.toggleGridView();
    }
    return this.gridViewActive;
  }

  async initMediaDevices() {
    try {
      await this.getAudioAndVideoTracks();

      let { videoTrack, audioTrack } = this;

      setProperties(this, {
        ...(audioTrack && {
          selectedAudioInputDeviceId: audioTrack?.mediaStreamTrack.getSettings().deviceId,
        }),
        ...(videoTrack && {
          selectedVideoDeviceId: videoTrack.mediaStreamTrack.getSettings().deviceId,
        }),
        isAudioPublished: !!audioTrack?.isEnabled,
        isVideoPublished: !!videoTrack?.isEnabled,
      });
      this._initAutoQuality();
    } catch (error) {
      if (didCancel(error)) return;

      await this.errorHandling.getDevicesError(
        error,
        this.localTracks.hasVideo,
        this.localTracks.hasAudio
      );
      return error;
    }
  }

  async joinTestRoom({ isWelcomeScreen } = { isWelcomeScreen: false }) {
    let { testToken, testSessionId } = this.roomModel;
    let properties = { token: testToken, sessionId: testSessionId, userName: this.userName };
    let inWaitingRoom = !this.isHost && !isWelcomeScreen;

    this.session.resetRoomProperties();
    this.session.setInWaitingRoom(inWaitingRoom);
    this._startLogger();

    if (inWaitingRoom) this.mixpanel.track('enter waiting room');

    let room = await this._joinRoom(properties);
    if (!room) return;

    this._setLocalParticipantProperties(this.localParticipant);

    if (!isWelcomeScreen) {
      this.#listenToRoomChannel();
    }
    this.localParticipant.on(PARTICIPANT_EVENTS.reconnecting, () => {
      set(this.localParticipant, 'reconnecting', true);
      set(this, 'localNetworkQualityLevel', NETWORK_QUALITY_SCORE.noConnection);
    });
    this.localParticipant.on(PARTICIPANT_EVENTS.reconnected, () =>
      set(this.localParticipant, 'reconnecting', false)
    );
  }

  async joinRoom() {
    let { token, sessionId } = this.roomModel;
    let properties = { token, sessionId, userName: this.userName };

    this.#listenToRoomChannel();

    let room = await this._joinRoom(properties);
    if (!room) return;
    window.addEventListener('beforeunload', () => {
      if (isEmberTesting()) return;
      if (this.session.isHost && this.room?.sid && this.session.isRecording) {
        this.recordingService.stopRecording(this.room.sid);
      }
      this.room?.disconnect();
      this.session.processEnding();
    });
    window.addEventListener('popstate', this.leaveRoom);

    this.call.connect();
    let localParticipant = room.localParticipant;
    this._setLocalParticipantProperties(localParticipant);
    this.#addressUnmutedTrackIssue(room);

    localParticipant.tracks.forEach(({ track }) => {
      this._ensureTrackConfirmed(track);
    });
    localParticipant.on(PARTICIPANT_EVENTS.networkQualityLevelChanged, (level, stats) => {
      this.autoQuality.handleNetworkLevelUpdate(level);
      this._updateNetworkQualityLevel(localParticipant, level);
      this.remoteLogger.info('%j', {
        message: PARTICIPANT_EVENTS.networkQualityLevelChanged,
        stats: formatNetworkQualityLog(stats),
        participantSid: this.localParticipant?.sid,
      });
    });
    localParticipant.on(PARTICIPANT_EVENTS.trackPublished, track => {
      if (isDataTrack(track)) return;

      notifyPropertyChange(localParticipant, 'tracks');
    });
    localParticipant.on(PARTICIPANT_EVENTS.trackUnpublished, track => {
      if (isDataTrack(track)) return;

      notifyPropertyChange(localParticipant, 'tracks');
    });
    this._handleReconnection(localParticipant);

    room.participants.forEach(this.participantConnected);

    this.#listenToRoomEvents();
    this.session.processStart();

    if (this.isHost) {
      this.session.setHostJoinTime(this.roomModel.hostJoinTime || moment());
      this.session.roomSid = this.room.sid;
    }
  }

  async getAudioAndVideoTracks() {
    if (
      (!this.audioTrack && !this.videoTrack) ||
      !this.audioTrack?.name.includes(this.userName) ||
      !this.videoTrack?.name.includes(this.userName)
    ) {
      await this.localTracks.getAudioAndVideoTracksTask.perform(this.userName, {
        audioPublished: this.isAudioPublished,
        videoPublished: this.isVideoPublished,
      });
    }
  }

  participantConnected = participant => {
    set(participant, 'independent', true);
    this.session.addParticipant(participant);

    participant.tracks.forEach(publication => this._trackPublished(publication, participant));
    participant.on(PARTICIPANT_EVENTS.trackPublished, publication =>
      this._trackPublished(publication, participant)
    );
    participant.on(PARTICIPANT_EVENTS.trackUnpublished, publication =>
      this._trackUnpublished(publication, participant)
    );

    participant.on(PARTICIPANT_EVENTS.trackSubscribed, track => {
      if (isDataTrack(track)) {
        track.on('message', data => this.dataTrackService.receiveMessage(JSON.parse(data)));
      }
    });

    participant.on(PARTICIPANT_EVENTS.trackDisabled, track =>
      this._toggleParticipantTrackProperties(track, participant, true)
    );
    participant.on(PARTICIPANT_EVENTS.trackEnabled, track =>
      this._toggleParticipantTrackProperties(track, participant, false)
    );

    set(participant, 'networkLevel', 4);
    participant.on(PARTICIPANT_EVENTS.networkQualityLevelChanged, level =>
      this._updateNetworkQualityLevel(participant, level)
    );

    this._handleReconnection(participant);
    this.soundNotification.playJoinSound();
    this.session.shareUserData(participant.identity);
  };

  _handleReconnection(participant) {
    participant.on(PARTICIPANT_EVENTS.reconnecting, () =>
      setProperties(participant, { reconnecting: true, networkLevel: 0 })
    );
    participant.on(PARTICIPANT_EVENTS.reconnected, () =>
      setProperties(participant, { reconnecting: false, networkLevel: 1 })
    );
  }

  _updateNetworkQualityLevel = (participant, level) => {
    set(participant, 'networkLevel', level);
  };

  _toggleParticipantTrackProperties(track, participant, isDisabled) {
    switch (track.kind) {
      case TRACK_KINDS.audio:
        set(participant, 'muted', isDisabled);
        break;
      case TRACK_KINDS.video:
        set(participant, 'noVideo', isDisabled);
        if (isDisabled) {
          set(participant, 'stalled', false);
        }
        break;
      default:
        return;
    }
    notifyPropertyChange(participant, 'tracks');
  }

  _trackPublished = (publication, participant) => {
    if (publication.track && !isDataTrack(publication)) {
      this._toggleParticipantTrackProperties(
        publication.track,
        participant,
        !publication.track.isEnabled
      );
    }

    if (!participant.name) {
      this._setRemoteParticipantProperties(participant, this.participants.length);
    }

    this.#checkRelatedParticipant(participant);

    publication.on(PUBLICATION_EVENTS.subscribed, track => {
      this.#handlePublicationSubscribed(publication, track, participant);
    });
    publication.on(PUBLICATION_EVENTS.unsubscribed, track => {
      if (isDataTrack(track)) return;

      let { type } = publication.trackInfo || parseTrackInfo(publication.trackName);
      if (type !== TRACK_TYPE.screensharing) return;

      this._clearSharingProperties();
    });

    publication.on(PUBLICATION_EVENTS.switchedOn, track =>
      this._toggleParticipantTrackProperties(track, participant, false)
    );
    publication.on(PUBLICATION_EVENTS.switchedOff, track =>
      this._toggleParticipantTrackProperties(track, participant, true)
    );
  };

  _trackUnpublished(publication, participant) {
    let { type } = publication.trackInfo || parseTrackInfo(publication.trackName);

    if (type === TRACK_TYPE.screensharing || type === TRACK_TYPE.screenAudio) return;

    this._toggleParticipantTrackProperties(publication, participant, true);
  }

  participantDisconnected = participant => {
    if (participant.isHost && this.room.state !== ROOM_STATES.disconnected) {
      this.#handleSessionEnd({ isHostLostConnection: participant.reconnecting });
    }

    this.session.removeParticipant(participant.identity);
    setProperties(this, {
      ...(this.dominantSpeaker === participant && { dominantSpeaker: null }),
      ...(this.selectedParticipant === participant && { selectedParticipant: null }),
      ...(this.shareParticipant === participant && {
        shareParticipant: undefined,
        sharedEntity: undefined,
      }),
    });
    if (this.shareParticipant === participant) {
      this.whiteboardService.setInitialData({ isLocked: true });
    }
    this.soundNotification.playLeaveSound();
  };

  handleDominantSpeakerChanged = newDominantSpeaker => {
    if (newDominantSpeaker === null) return;

    set(this, 'dominantSpeaker', newDominantSpeaker);
  };

  _initAutoQuality() {
    if (!(this.isAutoQuality && this.videoTrack)) return;

    this.autoQuality.initAuto(this.videoTrack);
  }

  async _joinRoom(properties) {
    let { token, sessionId } = properties;

    let error = await this.initMediaDevices();
    if (error) await this._joinRoom(properties);

    let tracks = this.localTracks.localTracks;
    let dataTrack = this.dataTrackService.createDataTrack(
      this.localTracks.trackName(TRACK_TYPE.data, this.userName)
    );

    try {
      let room = await this._connect(
        token,
        this._connectionOptions(sessionId, [...tracks, dataTrack])
      );

      let { localParticipant } = room;

      this.dataTrackService.initDataTrackPublished(localParticipant);
      this.listenToDataTrackMessages();

      if (this.roomModel.featureTwilioPeerToPeer) {
        this.dataTrackPublished.resolve();
      }
      localParticipant.on(PARTICIPANT_EVENTS.trackPublicationFailed, (error, localTrack) => {
        this._publishMediaTrack(localTrack);
      });
      localParticipant.on(PARTICIPANT_EVENTS.trackPublished, ({ track }) => {
        switch (true) {
          case isVideoTrack(track):
            set(this, 'videoTrackPublishing', false);
            break;
          case isAudioTrack(track):
            set(this, 'audioTrackPublishing', false);
            break;
          case isDataTrack(track):
            this.dataTrackPublished.resolve();
            break;
        }
      });

      set(localParticipant, 'networkLevel', 4);
      localParticipant.on(PARTICIPANT_EVENTS.networkQualityLevelChanged, level => {
        if (!this.isConnected) {
          this.autoQuality.handleNetworkLevelUpdate(level);
        }
        this._printNetworkQualityStats(level);
      });

      room.on(ROOM_EVENTS.reconnected, () => set(this, 'localNetworkQualityLevel', 2));

      room.on(ROOM_EVENTS.recordingStarted, () => {
        this.session.startRecording();
        if (this.session.isHost) return;
      });
      if (room.isRecording) {
        this.session.startRecording();
        information({ text: 'Clinician recording this meeting!' });
      }
      room.on(ROOM_EVENTS.recordingStopped, () => {
        this.session.stopRecording();
        if (this.session.isHost) return;
        this.floatingUiElements.showStoppedRecordingNotification();
      });

      this.room?.disconnect();
      setProperties(this, {
        room,
        localNetworkQualityLevel: undefined,
      });

      return room;
    } catch (error) {
      if (didCancel(error)) return;

      if (error.code === ERRORS.tooManyParticipants) {
        this.setConnectionError(error);
        this.router.transitionTo('appointment');
        return;
      }
      await this.errorHandling.connectRoomError(error, properties);
      location.reload();
    }
  }

  _connect() {
    return this._connectTwilioRoomTask.perform(...arguments);
  }

  _setRemoteParticipantProperties(participant, index = 0) {
    setProperties(participant, {
      muted: true,
      noVideo: true,
    });
    this._setParticipantProperties(participant, index);
  }

  _setLocalParticipantProperties(participant, index = 0) {
    setProperties(participant, {
      muted: !this.isAudioPublished,
      noVideo: !this.isVideoPublished,
      userData: this.session.userData,
      isHost: this.session.isHost,
    });
    this._setParticipantProperties(participant, index);
  }

  _setParticipantProperties(participant, index = 0) {
    setProperties(participant, {
      ...parseTrackInfo(participant.tracks.values().next().value?.trackName),
      logoColorIndex: this.uniqRandom.getItem(index).toString(),
    });
  }

  async handleVideoChange(deviceChanged = true) {
    this.autoQuality.stopAuto();
    if (!this.isVideoPublished) {
      return;
    }

    if (this.localParticipant) {
      let nonScreenVideoTracks = Array.from(this.localParticipant.videoTracks.values()).filter(
        publication => publication.track.type !== TRACK_TYPE.screensharing
      );

      this._disableTracks(nonScreenVideoTracks);
    }

    let track = await this.localTracks.getLocalVideoTrack({
      deviceId: this.selectedVideoDeviceId,
      userName: this.userName,
      deviceChanged,
    });

    this.localTracks.setLocalVideoTrack(track);

    try {
      await this._publishMediaTrack(track);
    } catch {
      // noop, will be retied in trackPublicationFailed
    }

    this._initAutoQuality();
  }

  async _publishMediaTrack(track) {
    if (!this.localParticipant || this.localParticipant.state === 'disconnected') return;
    if (isVideoTrack(track)) {
      set(this, 'videoTrackPublishing', true);
    }
    if (isAudioTrack(track)) {
      set(this, 'audioTrackPublishing', true);
      if (!track.noiseCancellation?.isEnabled && this.noiseCancellationEnabled) {
        throw new Error('Noise cancellation was not enabled for some reason');
      }
    }

    return this.localParticipant.publishTrack(track);
  }

  async handleVideoQualityChange(value) {
    await this.localTracks.changeVideoQuality(value);
    try {
      await this.handleVideoChange(false);
    } catch (err) {
      this.errorHandling.notifyError(err);
    }
  }

  async handleAudioInputChange() {
    if (this.localParticipant) {
      this._disableTracks([this.audioTrack]);
    }
    let track = await this.localTracks.getLocalAudioTrack(
      this.selectedAudioInputDeviceId,
      this.userName
    );
    if (!this.isAudioPublished) {
      track.disable();
    }
    await this._publishMediaTrack(track);
  }

  handleAudioOutputChange() {
    document.querySelectorAll('audio').forEach(audioElement => {
      audioElement.setSinkId?.(this.selectedAudioOutputDeviceId);
    });
  }

  async toggleLocalAudio() {
    let { audioTrack } = this;
    if (!audioTrack) return;
    audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    if (this.localParticipant) {
      set(this.localParticipant, 'muted', !audioTrack.isEnabled);
    }
    set(this, 'isAudioPublished', audioTrack.isEnabled);
  }

  async toggleLocalVideo() {
    if (!this.room) return;

    let videoTrack = this.localTracks.videoTrack;

    if (videoTrack) {
      this.localParticipant?.unpublishTrack(videoTrack);
      this.localTracks.removeLocalVideoTrack();
      this.autoQuality.stopAuto();
    } else if (this.localParticipant) {
      let newTrack = await this.localTracks.getLocalVideoTrack({
        deviceId: this.selectedVideoDeviceId,
        userName: this.userName,
      });
      this.localTracks.setLocalVideoTrack(newTrack);
      await this._publishMediaTrack(newTrack);
      this._initAutoQuality();
    }
    set(this.localParticipant, 'noVideo', !!videoTrack);
    this.persistentProperties.setProp('isVideoPublished', !videoTrack);
  }

  @action
  leaveRoom(options = {}) {
    let { endCall = true, hostLeft = false } = options;

    if (this.isDestroyed) return;
    if (this.session.isHost && this.session.isRecording)
      this.recordingService.stopRecording(this.room.sid);
    this.#handleRoomLeaving({ removeTracks: endCall });
    this.room?.participants.forEach(this.participantDisconnected);
    setProperties(this, {
      room: null,
      localNetworkQualityLevel: undefined,
      hideSelfView: false,
      dominantSpeaker: null,
      shareParticipant: undefined,
      selectedParticipant: undefined,
      gridViewActive: false,
      sharedEntity: undefined,
    });
    this.call.disconnect();
    this.soundNotification.resetDelay();
    this.chat.resetChatProperties();
    this.floatingUiElements.hideJoinRequestNotification();
    this.whiteboardService.setInitialData({ isLocked: true });

    if (endCall) {
      this.session.processEnding({ hostLeft });
    }

    window.removeEventListener('popstate', this.leaveRoom);
  }

  leaveTestRoom({ endCall } = {}) {
    this.#handleRoomLeaving();

    if (endCall) {
      this.roomChannel.unsubscribe();
    }
    set(this, 'room', null);
  }

  _disableTracks(tracks) {
    tracks.forEach(publishedTrack => {
      let track = publishedTrack.track || publishedTrack;

      if (!isDataTrack(track)) {
        track.stop();
      }
      this.localParticipant?.unpublishTrack(track);
    });
  }

  _printNetworkQualityStats(networkQualityLevel, _networkQualityStats) {
    set(this, 'localNetworkQualityLevel', networkQualityLevel);
  }

  _connectionOptions(sessionId, tracks) {
    return {
      ...TWILIO_CONNECTION_OPTIONS,
      tracks,
      name: sessionId,
      preferredVideoCodecs: this.featureThAdaptiveSimulcast
        ? 'auto'
        : [{ codec: 'VP8', simulcast: this._hasQueryParam('cast') }],
      iceTransportPolicy: this._hasQueryParam('relay') ? 'relay' : 'all',
    };
  }

  _startLogger() {
    if (!this._hasQueryParam('debug')) {
      return;
    }
    let format = log => ({
      appointmentToken: this.roomModel.roomId,
      level: log.level.label,
      message: JSON.stringify(log.message),
      participantSid: this.localParticipant?.sid,
      participantId: this.persistentProperties.uuid,
    });
    try {
      remote.apply(Logger, { url: LOGS_PATH, format });
    } catch {
      // noop
    }
    let logger = Logger.getLogger('twilio-video');
    logger.setLevel('debug');
  }

  toggleHideSelfView() {
    if (!this.hideSelfView && this.selectedParticipant === this.localParticipant) {
      set(this, 'selectedParticipant', undefined);
    }
    set(this, 'hideSelfView', !this.hideSelfView);
  }

  toggleGridView() {
    set(this, 'gridViewActive', !this.gridViewActive);
  }

  async _startScreensharing() {
    try {
      let { videoTrack, audioTrack } = await this.localTracks.getScreenTrack(
        this.localParticipant,
        this.userName
      );
      if (this.sharedEntity) {
        [videoTrack, audioTrack].compact().forEach(track => {
          this.localParticipant.unpublishTrack(track);
          track.stop();
        });
        dialogs.cannotStartSharing();
        return;
      }
      this.triggerSharing();
      videoTrack.on('stopped', _localTrack => this._stopScreensharing());
      setProperties(this, {
        sharedEntity: SHAREABLE_ENTITIES.screen,
        shareParticipant: this.localParticipant,
      });
      this.mixpanel.track('screenshare started');
    } catch (error) {
      switch (error.message) {
        case 'Permission denied': {
          break;
        }
        case 'Permission denied by system': {
          this.errorHandling.systemPermissionsError();
          break;
        }
        default: {
          this.errorHandling.notifyError(error);
        }
      }
    }
  }

  _stopScreensharing() {
    let { screenTrack, screenAudioTrack, localParticipant } = this;
    [screenTrack, screenAudioTrack].filter(Boolean).forEach(track => {
      localParticipant.unpublishTrack(track);
      track.stop();
    });
    this._clearSharingProperties();
  }

  _clearSharingProperties() {
    setProperties(this, {
      sharedEntity: undefined,
      shareParticipant: undefined,
      whiteboardTrack: undefined,
    });

    this.whiteboardService.setInitialData({ state: null, isLocked: true });
  }

  toggleScreenSharing() {
    if (this.isSharingScreen) {
      this._stopScreensharing();
      this.mixpanel.track('screenshare stopped');
    } else {
      this._startScreensharing();
    }
  }

  _startWhiteboardSharing() {
    if (this.shareParticipant) {
      dialogs.cannotStartSharing();
      return;
    }
    this.triggerSharing();
    setProperties(this, {
      sharedEntity: SHAREABLE_ENTITIES.whiteboard,
      shareParticipant: this.localParticipant,
      startSharingTime: moment(),
    });
    this.whiteboardService.setInitialData({ isLocked: true });
    this.dataTrackService.sendMessage(
      { type: GLOBAL_EVENT_TYPES.startWhiteboard, isWhiteboardLocked: this.isWhiteboardLocked },
      GLOBAL_EVENT
    );
  }

  async shareWhiteboardTrack(whiteboardTrack) {
    try {
      let name = this.localTracks.trackName(TRACK_TYPE.screensharing, this.userName);
      let publicationVideo = await this.localParticipant.publishTrack(whiteboardTrack, { name });

      set(this, 'whiteboardTrack', publicationVideo.track);
    } catch (error) {
      this.errorHandling.notifyError(error);
    }
  }

  async _stopWhiteboardSharing({ ensure } = { ensure: true }) {
    if (ensure) {
      let { dismiss } = await dialogs.ensureStopWhiteboardSharing();
      if (dismiss) return;
    }

    this.dataTrackService.sendMessage({ type: GLOBAL_EVENT_TYPES.stopWhiteboard }, GLOBAL_EVENT);
    if (this.whiteboardTrack) {
      this.localParticipant.unpublishTrack(this.whiteboardTrack);
    }
    if (this.isSharing) {
      this.mixpanel.track('whiteboard stopped');
    }
    this.whiteboardService.stopVideoStream();
    this._clearSharingProperties();
  }

  toggleWhiteboardSharing() {
    if (this.isSharingWhiteboard) {
      this._stopWhiteboardSharing();
    } else {
      this._startWhiteboardSharing();
    }
  }

  toggleWhiteboardLocked() {
    if (this.isSharing) {
      this.dataTrackService.sendMessage(
        {
          type: this.isWhiteboardLocked
            ? GLOBAL_EVENT_TYPES.unlockWhiteboard
            : GLOBAL_EVENT_TYPES.lockWhiteboard,
        },
        GLOBAL_EVENT
      );
    }
    this.whiteboardService.setInitialData({ isLocked: !this.isWhiteboardLocked });
  }

  handlePin(participant) {
    if (participant === this.selectedParticipant) {
      set(this, 'selectedParticipant', undefined);
    } else {
      set(this, 'selectedParticipant', participant);
    }
  }

  triggerSharing() {
    if (this.gridViewActive) {
      this.toggleGridView();
    }
    this.handlePin();
  }

  setVideoProcessorType(videoTrack, processorType) {
    return this.localTracks.setTrackVideoProcessor(videoTrack, processorType);
  }

  createVideoTrack(params) {
    return this.localTracks.getLocalVideoTrack(params);
  }

  _hasQueryParam(param) {
    return window.location.search.includes(param);
  }

  listenToDataTrackMessages() {
    this.dataTrackService.addMessageHandler(GLOBAL_EVENT, this._handleGlobalEvents.bind(this));
  }

  _handleGlobalEvents(messageProperties) {
    switch (messageProperties.message.type) {
      case GLOBAL_EVENT_TYPES.startWhiteboard:
        this._handleRemoteWhiteboardStart(messageProperties);
        break;
      case GLOBAL_EVENT_TYPES.stopWhiteboard:
        this._clearSharingProperties();
        break;
      case GLOBAL_EVENT_TYPES.lockWhiteboard:
      case GLOBAL_EVENT_TYPES.unlockWhiteboard:
        this.toggleWhiteboardLocked();
        break;
      case GLOBAL_EVENT_TYPES.collectClientConsent:
        return this.#handleClientConsent(messageProperties.message);
    }
  }

  #handleClientConsent(messageProperties) {
    if (!this.roomModel.featureAiNotetaker) return;
    this.recordingService.handleClientConsent(messageProperties);
  }

  async _handleRemoteWhiteboardStart({ message, participantSid, timestamp }) {
    let participant = this.participants.findBy('identity', participantSid);

    if (this.isSharing) {
      if (moment(timestamp).isBefore(this.startSharingTime)) {
        this.isSharingScreen
          ? await this.toggleScreenSharing()
          : await this.toggleWhiteboardSharing();
        dialogs.cannotStartSharing();
      } else {
        return;
      }
    }
    setProperties(this, {
      sharedEntity: SHAREABLE_ENTITIES.whiteboard,
      shareParticipant: participant,
    });
    this.whiteboardService.setInitialData({
      state: message.fileData?.fileBase64,
      isLocked: message.isWhiteboardLocked,
    });
    this.triggerSharing();
  }

  #checkRelatedParticipant(participant) {
    let participants = this.participants.filterBy('uuid', participant.uuid);

    if (participants.length < 2) {
      set(participant, 'independent', true);
      return;
    }

    participants.forEach(participant => {
      if (participant.tracks.size === 1 && participant.type === TRACK_TYPE.screensharing) {
        set(participant, 'independent', false);
      }
    });
  }

  setConnectionError(error) {
    set(this, 'connectionError', error);
  }

  #listenToRoomEvents() {
    this.room.on(ROOM_EVENTS.participantConnected, this.participantConnected);
    this.room.on(ROOM_EVENTS.participantDisconnected, this.participantDisconnected);
    this.room.on(ROOM_EVENTS.dominantSpeakerChanged, this.handleDominantSpeakerChanged);
    this.room.on(ROOM_EVENTS.disconnected, (_room, error) => {
      if (error) {
        this.errorHandling.roomDisconnectedError();
        this.roomChannel.unsubscribe();
      }
    });
  }

  #listenToRoomChannel() {
    if (!this.featureThClinicianAuth || this.roomChannel.connected) return;

    this.roomChannel.subscribe({
      roomId: this.roomModel.roomId,
      userName: this.persistentProperties.userName,
    });
    this.roomChannel.addMessageHandler(ROOM_CHANNEL_MESSAGE_TYPES.session, properties => {
      let { message, participantSid: senderUuid } = properties;

      let handler = {
        [SESSION_EVENTS_TYPES.admitJoin]: this.#handleJoinAdmit,
        [SESSION_EVENTS_TYPES.ended]: this.#handleSessionEnd,
        [SESSION_EVENTS_TYPES.joinRequest]: this.#handleJoinRequest,
        [SESSION_EVENTS_TYPES.declineJoin]: this.#handleJoinDecline,
        [SESSION_EVENTS_TYPES.shareUserData]: this.#handleSharedUserData,
        [SESSION_EVENTS_TYPES.sendToWaitingRoom]: this.#handleSendingToWaitingRoom,
        [SESSION_EVENTS_TYPES.participantUnsubscribed]: this.#handleParticipantUnsubscribed,
        [SESSION_EVENTS_TYPES.hostControlsChanged]: this.#handleHostControlsChanged,
        [SESSION_EVENTS_TYPES.trackSubscriptionConfirmed]: this.#handleTrackSubscriptionConfirmed,
      }[message.eventType];

      handler?.call(this, { message, senderUuid });
    });
  }

  #handleSharedUserData({ message, senderUuid: participantSid }) {
    this.session.setParticipantUserDataTask.perform({ userData: message.userData, participantSid });
  }

  #handleRoomLeaving({ removeTracks } = { removeTracks: true }) {
    this.session.setInWaitingRoom(!this.session.isHost);

    if (!this.room) return;

    if (removeTracks && this.localParticipant) {
      let { tracks } = this.localParticipant;

      this._disableTracks(tracks);
      this.localTracks.reset();
      this.ensureTrackConfirmedTask.cancelAll();
    }

    this.autoQuality.stopAuto();
    this.room.disconnect();
    this.room.off(ROOM_EVENTS.participantConnected, this.participantConnected);
    this.room.off(ROOM_EVENTS.participantDisconnected, this.participantDisconnected);
    this.room.off(ROOM_EVENTS.dominantSpeakerChanged, this.handleDominantSpeakerChanged);
  }

  #handleJoinRequest({ message }) {
    if (message.roomId !== this.roomModel.roomId) return;

    this.session.handleNewRequests(message.requests);
  }

  async #handleSessionEnd({ isHostLostConnection, senderUuid } = {}) {
    if (this.uuid === senderUuid) return;

    let shouldShowRatingPage = this.call.shouldShowRatingPage;

    this.leaveRoom({ hostLeft: true });
    await dialogs.appointmentEnded(isHostLostConnection);

    if (!isHostLostConnection && shouldShowRatingPage) {
      this.router.transitionTo('appointment.rating');
    } else if (isHostLostConnection) {
      this.router.transitionTo('appointment');
    } else {
      this.router.transitionTo('appointment.ended');
    }
  }

  async #handleJoinAdmit() {
    if (!this.inWaitingRoom) return;

    this.session.setInWaitingRoom(false);
    await this.joinRoom();
  }

  #handleJoinDecline() {
    this.session.handleJoinRequestDeclined();
  }

  #handleSendingToWaitingRoom() {
    this.leaveRoom({ endCall: false });
    this.session.showSentToWaitingRoomNotification();
    this.joinTestRoom();
  }

  #handleParticipantUnsubscribed({ message }) {
    this.session.cancelJoinRequest(message);
  }

  #handleHostControlsChanged({ message }) {
    if (this.isHost || !message?.changedControls) return;

    let { changedControls } = message;

    Object.entries(changedControls).forEach(([control, enabled]) => {
      if (control === HOST_CONTROLS.backgroundEnabled && !enabled) {
        this.setVideoProcessorType(this.videoTrack, null);
      } else if (control === HOST_CONTROLS.sharingEnabled && this.isSharing && !enabled) {
        this.isSharingScreen
          ? this._stopScreensharing()
          : this._stopWhiteboardSharing({ ensure: false });
      }
    });
    this.appointmentSettings.change(changedControls);
  }

  #handlePublicationSubscribed(publication, track, participant) {
    let trackInfo = parseTrackInfo(track.name);

    setProperties(publication, { trackInfo });
    switch (trackInfo.type) {
      case TRACK_TYPE.camera:
        set(participant, 'stalled', true);
        this._toggleParticipantTrackProperties(track, participant, !track.isEnabled);
        break;
      case TRACK_TYPE.screensharing:
      case TRACK_TYPE.screenAudio:
        if (this.isWhiteboarding) return;
        setProperties(this, {
          shareParticipant: participant,
          sharedEntity: SHAREABLE_ENTITIES.screen,
        });
        notifyPropertyChange(participant, 'tracks');
        this.triggerSharing();
        break;
      case TRACK_TYPE.audio:
        this._toggleParticipantTrackProperties(track, participant, !track.isEnabled);
        break;
      default:
        return;
    }
    this.session.notifyTwilioTrackSubscribed(track, participant);
  }

  #handleTrackSubscriptionConfirmed({ message }) {
    this.localTracks.handleTrackSubscriptionConfirmed(message.trackName);
  }

  _ensureTrackConfirmed(track) {
    if (this.isHost || !this.featureThClinicianAuth || !this.featureThEnsureTracks) return;

    this.ensureTrackConfirmedTask.perform(track);
  }

  ensureTrackConfirmedTask = task(async (track, retryCount = 1) => {
    await timeout(this.ensureTrackConfirmationTimeout);

    if (!track || track.isConfirmedByHost || this.room.state !== ROOM_STATES.connected) return;

    this.mixpanel.track('track publication failed', {
      'participant_sid': this.localParticipant.sid,
      duration: retryCount * 10,
      'track_type': isAudioTrack(track) ? TRACK_TYPE.audio : TRACK_TYPE.camera,
    });

    if (retryCount === MAX_TRACK_CONFIRMATION_RETRIES) {
      this.leaveRoom();
      await this.errorHandling.roomDisconnectedError();
      return;
    }

    let newTrack =
      this.room.state === ROOM_STATES.reconnecting ? track : await this.#republishMediaTrack(track);

    return this.ensureTrackConfirmedTask.perform(newTrack, retryCount + 1);
  });

  async #republishMediaTrack(track) {
    if (isAudioTrack(track)) {
      await this.handleAudioInputChange();
      return this.audioTrack;
    }
    if (isVideoTrack(track)) {
      await this.handleVideoChange(false);
      return this.videoTrack;
    }
  }

  #addressUnmutedTrackIssue() {
    if (!isSafari() || this.audioTrack?.isEnabled) return;

    let listener = () => {
      this.audioTrack.disable();
      this.audioTrack.off('started', listener);
    };
    this.audioTrack.enable();
    this.audioTrack.on('started', listener);

    return this.audioTrack.restart();
  }
}
