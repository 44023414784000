import * as dialogs from 'frontend/utils/dialogs';
import { HOST_CONTROLS } from 'frontend/constants/settings';
import { ROOM_CHANNEL_MESSAGE_TYPES, SESSION_EVENTS_TYPES } from 'frontend/constants';
import { action } from '@ember/object';
import { fetchMeetingData, fetchNearestChimeRegion } from 'frontend/utils/fetch-data';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { reads } from 'macro-decorators';
import { task } from 'ember-concurrency';
import { waitForRender } from 'ember-simplepractice/utils/waiters';
import Service, { service } from '@ember/service';
import moment from 'moment-timezone';

export default class AppointmentService extends Service {
  @service('chime.connection-health') connectionHealth;
  @service('chime.meeting-manager') meetingManager;
  @service('chime.remote-videos') remoteVideos;
  @service('chime.local-audio-video') localAudioVideo;
  @service('chime.roster') rosterService;
  @service('chime.content-sharing') contentSharing;
  @service('chime.sdk') chimeSdk;
  @service('chime.data-messaging') dataMessaging;
  @service('chime.event-manager') eventManager;
  @service appointmentSettings;
  @service audioVideoAdapter;
  @service chat;
  @service floatingUiElements;
  @service persistentProperties;
  @service roomChannel;
  @service router;
  @service session;
  @service uiElements;
  @service call;
  @service mixpanel;
  @service errorHandling;

  nearestRegion;

  @reads('call.shouldShowRatingPage') shouldShowRatingPage;
  @reads('session.roomModel') roomModel;
  @reads('session.isHost') isHost;
  @reads('roomModel.featureThClinicianAuth') featureThClinicianAuth;
  @reads('meetingManager.localAttendeeId') localAttendeeId;
  @reads('rosterService.remoteAttendeeIds') remoteAttendeeIds;
  @reads('rosterService.activeSpeakerId') activeSpeakerId;
  @reads('localAudioVideo.tileId') localTileId;
  @reads('uiElements.isGridView') isGridView;
  @reads('uiElements.pinnedAttendeeId') pinnedAttendeeId;

  get mainAttendeeId() {
    let [firstRemoteAttendeeId] = this.remoteAttendeeIds;

    return this.pinnedAttendeeId || this.activeSpeakerId || firstRemoteAttendeeId;
  }

  get hasMainView() {
    return !!(this.mainAttendeeId || this.contentSharing.sharedEntity);
  }

  setup() {
    return this.setupTask.perform();
  }

  initSessionTask = task(async ({ isTestSession = false } = {}) => {
    try {
      let { meeting, attendee } = await this.fetchMeetingDataTask.perform({ isTestSession });

      await this.meetingManager.initMeetingSession({ meeting, attendee });

      this.setupServices();

      await this.localAudioVideo.setupDevices();
      await this.#startChimeMeeting();
      if (isTestSession) return;
      this.eventManager.setup();
      this.call.connect();
    } catch (e) {
      if (this.errorHandling.isGetDevicesError(e)) {
        await this.errorHandling.getDevicesError(e);
      } else {
        this.errorHandling.notifyError(e);
        return this.errorHandling.roomDisconnectedError();
      }
    }
  });

  setupServices() {
    this.rosterService.setup();
    this.localAudioVideo.setup();
    this.connectionHealth.setup();
    this.dataMessaging.setup();
    this.contentSharing.setup();
    this.remoteVideos.setup();
    this.rosterService.subscribeToRosterChanges(this._handleRosterChanges);
    this.connectionHealth.subscribeToMeetingFail(this._handleMeetingFail);
  }

  async join() {
    let isTestSessionRequired = this.featureThClinicianAuth && !this.isHost;
    let isOnSession = this.meetingManager.meetingSession;

    if (!isOnSession) {
      await this.initSessionTask.perform({ isTestSession: isTestSessionRequired });
    }

    this.#subscribeToRoomChannel();
    this.session.setInWaitingRoom(!this.isHost);

    if (isTestSessionRequired) this.mixpanel.track('enter waiting room');

    this.session.processStart();
    this.session.setHostJoinTime(this.roomModel.hostJoinTime || moment());
  }

  @action
  leave(options = {}) {
    let { endCall = true, hostLeft = false } = options;

    if (endCall) {
      let onRouteDidChange = () => {
        if (this.isDestroyed) return;

        this.router.off('routeDidChange', onRouteDidChange);
        this.session.resetRoomProperties();
      };

      this.router.on('routeDidChange', onRouteDidChange);
      this.session.processEnding({ hostLeft });
      window.removeEventListener('beforeunload', this.pageLeaveHandler);
      window.removeEventListener('popstate', this.pageLeaveHandler);
      this.localAudioVideo.stopDevices();
    }

    this.#resetProperties();
    this.meetingManager.leave();
  }

  sendToWaitingRoom(attendeeId) {
    this.session.sendToWaitingRoom(this.rosterService.roster.get(attendeeId));
  }

  setupTask = task(async () => {
    let [region] = await Promise.all([fetchNearestChimeRegion(), this.chimeSdk.load()]);

    this.nearestRegion = region;
  });

  fetchMeetingDataTask = task(async ({ isTestSession }) => {
    await this.setupTask.last;
    let params = {
      roomId: this.roomModel.roomId,
      participantId: this.persistentProperties.uuid,
      connectionTest: isTestSession,
      mediaRegion: this.nearestRegion,
    };

    return fetchMeetingData(params);
  });

  @action
  _handleRosterChanges({ attendee, isPresent }) {
    if (isPresent || !attendee.isHost) return;

    this.#handleSessionEnd({ isHostLostConnection: attendee.lostConnection });
  }

  @action
  _handleMeetingFail() {
    this.errorHandling.roomDisconnectedError();
    this.leave();
  }

  #resetProperties() {
    this.localAudioVideo.reset();
    this.call.disconnect();
    this.contentSharing.reset();
    this.rosterService.reset();
    this.eventManager.reset();
    this.connectionHealth.reset();
    this.session.setInWaitingRoom(!this.isHost);
    this.uiElements.reset();
    this.floatingUiElements.hideJoinRequestNotification();
    this.chat.resetChatProperties();
    this.dataMessaging.resetDataMessaging();
    this.remoteVideos.reset();
  }

  async #startChimeMeeting() {
    await this.meetingManager.start();
  }

  #subscribeToRoomChannel() {
    if (!this.featureThClinicianAuth || this.roomChannel.connected) return;

    this.roomChannel.subscribe({
      roomId: this.roomModel.roomId,
      userName: this.persistentProperties.userName,
    });
    this.roomChannel.addMessageHandler(ROOM_CHANNEL_MESSAGE_TYPES.session, properties => {
      let { message, participantSid: senderUuid } = properties;

      let handler = {
        [SESSION_EVENTS_TYPES.admitJoin]: this.#handleJoinAdmit,
        [SESSION_EVENTS_TYPES.declineJoin]: this.#handleJoinDecline,
        [SESSION_EVENTS_TYPES.ended]: this.#handleSessionEnd,
        [SESSION_EVENTS_TYPES.hostControlsChanged]: this.#handleHostControlsChanged,
        [SESSION_EVENTS_TYPES.joinRequest]: this.#handleJoinRequest,
        [SESSION_EVENTS_TYPES.participantUnsubscribed]: this.#handleParticipantUnsubscribed,
        [SESSION_EVENTS_TYPES.sendToWaitingRoom]: this.#handleSendingToWaitingRoom,
        [SESSION_EVENTS_TYPES.shareUserData]: this.#handleSharedUserData,
      }[message.eventType];

      handler?.call(this, { message, senderUuid });
    });

    if (isEmberTesting()) return;

    this.pageLeaveHandler = () => {
      this.roomChannel.unsubscribe();
      this.leave();
    };
    window.addEventListener('beforeunload', this.pageLeaveHandler);
    window.addEventListener('popstate', this.pageLeaveHandler);
  }

  async #handleJoinAdmit() {
    if (!this.session.inWaitingRoom) return;

    await this.initSessionTask.last;
    this.leave({ endCall: false });
    this.session.setInWaitingRoom(false);
    await this.initSessionTask.perform();
  }

  #handleJoinDecline() {
    this.session.handleJoinRequestDeclined();
  }

  #handleJoinRequest({ message }) {
    if (message.roomId !== this.roomModel.roomId) return;

    this.session.handleNewRequests(message.requests);
  }

  async #handleSessionEnd({ senderUuid, isHostLostConnection } = {}) {
    if (this.persistentProperties.uuid === senderUuid) return;

    let shouldShowRatingPage = this.shouldShowRatingPage;

    this.leave({ hostLeft: true });
    await dialogs.appointmentEnded(isHostLostConnection);
    if (!isHostLostConnection && shouldShowRatingPage) {
      this.router.transitionTo('appointment.rating');
    } else if (isHostLostConnection) {
      this.router.transitionTo('appointment');
    } else {
      this.router.transitionTo('appointment.ended');
    }
  }

  #handleSharedUserData({ message, senderUuid: participantSid }) {
    this.rosterService.setAttendeeUserData(participantSid, message.userData);
  }

  async #handleSendingToWaitingRoom() {
    let observer = {
      audioVideoDidStop: async () => {
        await waitForRender();
        await this.initSessionTask.perform({ isTestSession: true });
        this.mixpanel.track('enter waiting room');
      },
    };
    // eslint-disable-next-line ember/no-observers
    this.meetingManager.audioVideo?.addObserver(observer);
    this.leave({ endCall: false });
    this.session.showSentToWaitingRoomNotification();
  }

  #handleParticipantUnsubscribed({ message }) {
    this.session.cancelJoinRequest(message);
  }

  #handleHostControlsChanged({ message }) {
    if (this.isHost || !message?.changedControls) return;

    let { changedControls } = message;

    Object.entries(changedControls).forEach(([control, enabled]) => {
      if (enabled) return;

      if (control === HOST_CONTROLS.backgroundEnabled) {
        this.localAudioVideo.updateVideoProcessor(null);
      } else if (control === HOST_CONTROLS.sharingEnabled) {
        this.contentSharing.stopSharing({ showPrompt: false });
      }
    });
    this.appointmentSettings.change(changedControls);
  }
}
