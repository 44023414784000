import { SCREEN_CONSTRAINTS } from 'frontend/constants';
import { isSafari } from 'frontend/utils/detect-browser';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';

@classic
export default class MediaDevicesService extends Service {
  @service persistentProperties;
  @service devicesAdapter;

  @tracked devices = [];
  @tracked isPermissionResolved = false;

  get videoDevices() {
    return this.devices.filter(device => device.kind === 'videoinput');
  }

  get audioInputDevices() {
    return this.devices.filter(device => device.kind === 'audioinput');
  }

  get audioOutputDevices() {
    return this.devices.filter(device => device.kind === 'audiooutput');
  }

  getDevices = async () => {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }
    this.devices = await this.enumerateDevices();
  };

  trackDevices() {
    // Backward compatibility with old Safari versions
    if (navigator.mediaDevices.addEventListener) {
      navigator.mediaDevices.addEventListener('devicechange', this.getDevices);
    } else if (navigator.mediaDevices.ondevicechange) {
      navigator.mediaDevices.ondevicechange = this.getDevices;
    }
    return this.getDevices();
  }

  // workaround for stubbing in tests
  enumerateDevices() {
    return navigator.mediaDevices.enumerateDevices();
  }

  async getUserMedia(constraints = { audio: true, video: true }) {
    try {
      return await navigator.mediaDevices.getUserMedia(constraints);
    } finally {
      this.isPermissionResolved = true;
    }
  }

  getDisplayMedia() {
    let constraint = this.screenConstraint;
    if (navigator.mediaDevices.getDisplayMedia) {
      return navigator.mediaDevices.getDisplayMedia(constraint);
    }

    return navigator.getDisplayMedia(constraint);
  }

  get screenConstraint() {
    return isSafari() ? SCREEN_CONSTRAINTS.video : SCREEN_CONSTRAINTS;
  }

  async takeDevicesControl() {
    await this.getDevices();
    let shouldAskForMediaPermissions =
      this.videoDevices.every(this.isDeviceUnavailable) ||
      this.audioInputDevices.every(this.isDeviceUnavailable) ||
      this.audioOutputDevices.every(this.isDeviceUnavailable);

    if (shouldAskForMediaPermissions) {
      let mediaStream = await this.getUserMedia();
      mediaStream.getTracks().forEach(track => track.stop());
    } else {
      this.isPermissionResolved = true;
    }
  }

  isDeviceUnavailable(device) {
    return !(device.deviceId && device.label);
  }
}
