import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import { modifier } from 'ember-modifier';
import { offset } from '@floating-ui/dom';
import { on } from '@ember/modifier';
import { reads } from 'macro-decorators';
import { service } from '@ember/service';
import AttachPopover from 'ember-attacher/components/attach-popover';
import Component from '@glimmer/component';
import styles from './share-popover.module.scss';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
export default class CallControlsSharePopover extends Component {
    @service('twilio/room')
    twilioRoom;
    @service
    session;
    @service
    audioVideoAdapter;
    @service
    floatingUiElements;
    @reads('session.roomModel.featureThChime')
    featureThChime;
    @reads('floatingUiElements.controlsShown')
    controlsShown;
    middleware = [
        offset(6)
    ];
    displayMediaAvailable = navigator.mediaDevices.getDisplayMedia || navigator.getDisplayMedia;
    @action
    toggleScreenShare() {
        this.audioVideoAdapter.toggleScreenSharing();
    }
    @action
    toggleWhiteboardShare() {
        this.audioVideoAdapter.toggleWhiteboardSharing();
    }
    onInsert = modifier((element1, _1, namedArguments1)=>{
        let { attacher: attacher1 } = namedArguments1;
        this.args?.onPopoverInsert(attacher1);
    });
    static{
        template(`
    {{#if this.controlsShown}}
      <AttachPopover
        @class={{styles.popover}}
        @placement="top-start"
        @renderInPlace={{true}}
        @middleware={{this.middleware}}
        @explicitTarget={{@target}}
        as |attacher|
      >
        <p>Share</p>
        <ul
          role="menu"
          class={{styles.sharemenu}}
          id={{@ariaControls}}
          {{this.onInsert attacher=attacher}}
        >
          <li role="none">
            <button
              role="menuitem"
              aria-label="Share a screen"
              class={{unless this.displayMediaAvailable "disabled"}}
              data-test-share-screen
              type="button"
              {{on "click" this.toggleScreenShare}}
            >
              {{svgJar "computer-screen" class=styles.icon}}
              <span>Your screen</span>
            </button>
          </li>
          <li role="none">
            <button
              role="menuitem"
              aria-label="Share a whiteboard"
              data-test-share-whiteboard
              type="button"
              {{on "click" this.toggleWhiteboardShare}}
            >
              {{svgJar "whiteboard" class=styles.icon}}
              <span>Whiteboard</span>
            </button>
          </li>
        </ul>
      </AttachPopover>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
