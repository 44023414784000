import { CHIME_MEETING_RECONNECT_TIMEOUT_MS } from 'frontend/constants/chime';
import { DefaultModality } from 'amazon-chime-sdk-js';
import { Randomizer } from 'frontend/utils/number-array';
import { TrackedMap } from 'tracked-built-ins';
import { action } from '@ember/object';
import { reads } from 'macro-decorators';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import Attendee from 'frontend/entities/chime/attendee';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';

@classic
export default class ChimeRosterService extends Service {
  @service('chime.meeting-manager') meetingManager;
  @service persistentProperties;
  @service session;
  @service soundNotification;
  @service uiElements;

  @tracked activeSpeakerId = null;

  roster = new TrackedMap();
  uniqRandom = new Randomizer(10);
  attendeeReconnectTimeout = CHIME_MEETING_RECONNECT_TIMEOUT_MS;
  attendeeTimeouts = {};
  #rosterChangeListeners = [];

  @reads('meetingManager.localAttendeeId') localAttendeeId;
  @reads('meetingManager.audioVideo') audioVideo;
  @reads('session.firstParticipantJoinTime') firstParticipantJoinTime;
  @reads('uiElements.isGridView') isGridView;
  @reads('uiElements.pinnedAttendeeId') pinnedAttendeeId;

  setup() {
    let { DefaultActiveSpeakerPolicy } = this.meetingManager.chimeSdk;
    let activeSpeakerPolicy = new DefaultActiveSpeakerPolicy();
    this.audioVideo.realtimeSubscribeToAttendeeIdPresence(this.onRosterUpdate);
    this.audioVideo.subscribeToActiveSpeakerDetector(
      activeSpeakerPolicy,
      this.onActiveSpeakerChange
    );
    this.roster.set(
      this.localAttendeeId,
      new Attendee({
        ...this.session.userData,
        logoColor: this.uniqRandom.getItem(0),
      })
    );
  }

  waitForAttendeeToReconnectTask = task(async (chimeAttendeeId, externalUserId) => {
    await timeout(this.attendeeReconnectTimeout);
    this.handleAttendeeLeave(chimeAttendeeId, externalUserId);
  });

  get remoteAttendeeIds() {
    return [...this.roster.keys()].filter(val => val !== this.localAttendeeId);
  }

  get size() {
    return this.roster.size;
  }

  get hasRemoteAttendees() {
    return this.size > 1;
  }

  isLocal(attendeeId) {
    return this.localAttendeeId === attendeeId;
  }

  get localAttendeeData() {
    return this.getAttendee(this.localAttendeeId);
  }

  @action
  onRosterUpdate(chimeAttendeeId, present, externalUserId, dropped) {
    let modality = new DefaultModality(chimeAttendeeId);
    let attendeeId = modality.base();

    if (modality.hasModality(DefaultModality.MODALITY_CONTENT)) return;
    if (dropped) {
      this.setAttendeeData(chimeAttendeeId, { lostConnection: true });
      this.attendeeTimeouts[chimeAttendeeId] = this.waitForAttendeeToReconnectTask.perform(
        chimeAttendeeId,
        externalUserId
      );
      return;
    }
    if (!present) return this.handleAttendeeLeave(chimeAttendeeId, externalUserId);

    if (this.attendeeTimeouts[chimeAttendeeId]) {
      this.attendeeTimeouts[chimeAttendeeId].cancel();
      delete this.attendeeTimeouts[chimeAttendeeId];
      this.setAttendeeData(chimeAttendeeId, { lostConnection: false });
    }

    if (
      this.roster.has(chimeAttendeeId) ||
      attendeeId !== chimeAttendeeId ||
      this.localAttendeeId === attendeeId
    )
      return;

    this.soundNotification.playJoinSound();

    if (!this.firstParticipantJoinTime) {
      this.session.setFirstParticipantJoinTime(moment());
    }

    this.session.shareUserData(externalUserId);

    let attendee = { chimeAttendeeId, connected: moment() };

    if (externalUserId) {
      attendee.externalUserId = externalUserId;
      attendee.logoColor = this.uniqRandom.getItem(this.size + 1).toString();
    }

    this.roster.set(attendeeId, new Attendee(attendee));
    this.setAttendeeData(attendeeId, attendee);
    this.session.addParticipant({ identity: externalUserId });
    this.#publishRosterChanges(attendee, true);
  }

  @action
  onActiveSpeakerChange([activeSpeakerId]) {
    if (
      !this.roster.has(activeSpeakerId) ||
      [this.localAttendeeId, this.activeSpeakerId].includes(activeSpeakerId)
    )
      return;

    this.activeSpeakerId = activeSpeakerId;
  }

  setAttendeeUserData(externalUserId, userData) {
    this.setUserDataTask.perform(externalUserId, userData);
  }

  setUserDataTask = task(async (externalUserId, userData, performCount = 0) => {
    if (!externalUserId || !userData || performCount > 3) return;

    let [attendeeId, attendeeData] =
      [...this.roster].find(([_, data]) => data.externalUserId === externalUserId) || [];

    if (!attendeeId) {
      await timeout(500);
      return this.setUserDataTask.perform(externalUserId, userData, performCount + 1);
    }

    let isHost = userData.id === this.session.roomModel.clinicianId;

    this.setAttendeeData(attendeeId, { ...attendeeData, ...userData, isHost });
  });

  handleAttendeeLeave(chimeAttendeeId, externalUserId) {
    let attendeeData = this.getAttendee(chimeAttendeeId);

    if (!attendeeData) return;
    this.soundNotification.playLeaveSound();
    this.#publishRosterChanges(attendeeData, false);
    this.roster.delete(chimeAttendeeId);
    this.session.removeParticipant(externalUserId);

    if (this.activeSpeakerId === chimeAttendeeId) {
      this.activeSpeakerId = null;
    }
    if (this.pinnedAttendeeId === chimeAttendeeId) {
      this.uiElements.handlePin(chimeAttendeeId);
    }

    if (this.size > 1 || !this.isGridView) return;

    this.uiElements.toggleGridView();
  }

  reset() {
    this.waitForAttendeeToReconnectTask.cancelAll();
    this.audioVideo?.realtimeUnsubscribeToAttendeeIdPresence(this.onRosterUpdate);
    this.audioVideo?.unsubscribeFromActiveSpeakerDetector(this.onRosterUpdate);
    this.activeSpeakerId = null;
    this.roster.clear();
    this.#rosterChangeListeners = [];
  }

  getAttendee(attendeeId) {
    return this.roster.get(attendeeId);
  }

  setAttendeeData(attendeeId, data) {
    let attendeeData = this.getAttendee(attendeeId);
    if (!attendeeData) return;
    attendeeData.update(data);
  }

  subscribeToRosterChanges(callback) {
    this.#rosterChangeListeners.push(callback);
  }

  unsubscribeFromRosterChanges(callback) {
    this.#rosterChangeListeners = this.#rosterChangeListeners.filter(cb => cb !== callback);
  }

  #publishRosterChanges(attendee, isPresent) {
    this.#rosterChangeListeners.forEach(callback => callback({ attendee, isPresent }));
  }
}
